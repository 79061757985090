import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core";
import { EventQuotaDetailedI, EventQuotaService } from "../../../services/event.quota.service";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { MultiselectModelI } from "../../s25-multiselect/s25.multiselect.component";
import { Bind } from "../../../decorators/bind.decorator";

@TypeManagerDecorator("s25-ng-edit-event-quotas")
@Component({
    selector: "s25-ng-edit-event-quotas",
    template: ` @if (init) {
        <div>
            <label class="ngInlineBlock c-margin-right--double">
                <span class="ngBold ngInlineBlock c-margin-bottom--quarter">Name</span>
                <s25-ng-editable-text
                    [max]="40"
                    [allowEmpty]="false"
                    [alwaysEditing]="alwaysEditing"
                    [(val)]="model.itemName"
                ></s25-ng-editable-text>
            </label>
            <div class="{{ model.type.readOnly ? 'ngInlineBlock ' : 'ngBlock' }}">
                <span class="ngBold ngInlineBlock c-margin-bottom--quarter">Type</span>
                @if (model.type && model.type.readOnly) {
                    <p>{{ model.type.itemName }}</p>
                } @else {
                    <p class="ngFinePrint c-margin-bottom--quarter">
                        Choose whether reservation totals should be counted by user or by primary organization
                    </p>
                    <div class="flexRow">
                        <s25-ng-radio
                            [name]="'quotaType'"
                            [(modelValue)]="model.type.itemId"
                            [value]="$any('3')"
                            (modelValueChange)="setQuotaType()"
                        >
                            User
                        </s25-ng-radio>
                        <s25-ng-radio
                            [name]="'quotaType'"
                            [(modelValue)]="model.type.itemId"
                            [value]="$any('2')"
                            (modelValueChange)="setQuotaType()"
                        >
                            Organization
                        </s25-ng-radio>
                    </div>
                }
                <ng-template #readOnly>
                    <p>{{ model.type.itemName }}</p>
                </ng-template>
            </div>
            @if (model.type && model.type.itemId == "2") {
                <span class="ngBold ngBlock c-margin-bottom--quarter  c-margin-top--half">Organizations</span>
                <p class="ngFinePrint c-margin-bottom--quarter">
                    This quota will be applied when the primary organization of the event is in the list below
                </p>
                <s25-ng-multiselect-search-criteria
                    [type]="'organizations'"
                    [modelBean]="organizations"
                    [selectedItems]="model.organizations"
                    [popoverOnBody]="true"
                    [popoverPlacement]="'right'"
                ></s25-ng-multiselect-search-criteria>
            }
            <div class="ngBlock c-margin-top--half">
                <label class="ngBold c-margin-bottom--quarter">State</label>
                <s25-toggle-button
                    [(modelValue)]="model.active"
                    [description]="'Activate or deactivate quota'"
                    [falseLabel]="'Inactive'"
                    [trueLabel]="'Active'"
                    [toggleLabel]="'Choose quota state'"
                ></s25-toggle-button>
            </div>
            <label class="ngBold c-margin-top--half c-margin-bottom--quarter"
                >Included Events
                <p class="ngFinePrint c-margin-bottom--quarter">
                    Should this quota consider existing occurrences by the user or organization. Select "Single Event
                    Only" for use with an embedded form to be used with guest users.
                </p>
                <s25-toggle-button
                    [(modelValue)]="model.skipExisting"
                    [description]="'Activate or deactivate quota'"
                    [falseLabel]="'All Events'"
                    [trueLabel]="'Single Event Only'"
                    [toggleLabel]="'Choose quota scope'"
                ></s25-toggle-button>
            </label>
            <div class="ngInlineBlock c-margin-top--half">
                <div class="float-left">
                    <label class="ngBold c-margin-bottom--quarter"
                        >Occurrence Count
                        <s25-ng-editable-number
                            [allowEmpty]="true"
                            [alwaysEditing]="alwaysEditing"
                            [(val)]="model.quantity"
                        ></s25-ng-editable-number>
                    </label>
                </div>
                <div class="c-margin-left--double float-right">
                    <span class="ngBold c-margin-bottom--quarter">Total Time</span>
                    <s25-ng-editable-duration
                        [allowEmpty]="true"
                        [alwaysEditing]="alwaysEditing"
                        [(val)]="model.maxTime"
                        [onUpdate]="updateDuration"
                    ></s25-ng-editable-duration>
                </div>
            </div>
            <div class="ngBlock c-margin-top--half">
                <label class="ngBold ngInlineBlock c-margin-bottom--quarter"
                    >Duration
                    <s25-ng-editable-number
                        [min]="0"
                        [allowEmpty]="false"
                        [alwaysEditing]="alwaysEditing"
                        [(val)]="model.duration"
                    ></s25-ng-editable-number>
                </label>
                <div class="flexRow">
                    <s25-ng-radio [name]="'durationUnit'" [(modelValue)]="model.durationType" [value]="$any('D')">
                        Days
                    </s25-ng-radio>
                    <s25-ng-radio [name]="'durationUnit'" [(modelValue)]="model.durationType" [value]="$any('M')">
                        Months
                    </s25-ng-radio>
                    <s25-ng-radio [name]="'durationUnit'" [(modelValue)]="model.durationType" [value]="$any('Y')">
                        Years
                    </s25-ng-radio>
                </div>
            </div>
            @if (model.type?.itemId != 99) {
                <div class="ngBlock c-margin-top--half">
                    <span class="ngBold ngInlineBlock">Application</span>
                    <p class="ngFinePrint c-margin-bottom--quarter">
                        Should quotas be applied to locations individually or the entire group?
                    </p>
                    <div class="flexRow">
                        <s25-ng-radio [name]="'groupBy'" [(modelValue)]="model.groupItems" [value]="false">
                            Individually
                        </s25-ng-radio>
                        <s25-ng-radio [name]="'groupBy'" [(modelValue)]="model.groupItems" [value]="true">
                            Group
                        </s25-ng-radio>
                    </div>
                </div>
            }
            <ng-container>
                <span class="ngBold ngBlock c-margin-bottom--quarter c-margin-top--half">Locations</span>
                <s25-ng-multiselect-search-criteria
                    [type]="'locations'"
                    [modelBean]="locations"
                    [selectedItems]="model.locations"
                    [popoverOnBody]="true"
                    [popoverPlacement]="'right'"
                ></s25-ng-multiselect-search-criteria>
            </ng-container>
            <div class="ngBlock c-margin-top--half">
                <div class="ngBold ngInlineBlock">Security Groups</div>
                <p class="ngFinePrint c-margin-bottom--quarter">
                    Quotas will only apply to users without override FLS for event quotas. Choose no security groups to
                    apply to all users without quota override.
                </p>
                <!--//TODO: deep link to https://25live.collegenet.com/burnside/groups/#/multi/admin_questions?group_id=52%20220-->
                <s25-ng-multiselect-search-criteria
                    [type]="'securityGroups'"
                    [modelBean]="secGroups"
                    [selectedItems]="model.secGroups"
                    [popoverOnBody]="true"
                    [popoverPlacement]="'right'"
                ></s25-ng-multiselect-search-criteria>
            </div>
            <div class="ngBlock">
                <label class="ngBold ngInlineBlock c-margin-bottom--quarter"
                    >Warning Message
                    <textarea
                        [(ngModel)]="model.comment"
                        rows="5"
                        cols="60"
                        class="ngBlock c-margin-top--half"
                        id="quotaWarningMessage"
                    ></textarea>
                </label>
            </div>
        </div>
    }`,
    styles: `
        .flexRow {
            display: flex;
            gap: 1em;
        }

        ::ng-deep .s25-multiselect-popup-container {
            max-width: 50vw;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25EditEventQuotasComponent implements OnInit {
    @Input() model: EventQuotaDetailedI = {};
    @Input() alwaysEditing: boolean = true;
    @Input() isNew: boolean = true; //Creating new vs edit mode
    @Input() onSave: any; //function
    @Input() cancel: any; //function

    init: boolean;
    organizations: MultiselectModelI = { showResult: true, showMatching: true, filterFavorites: false };
    locations: MultiselectModelI = { showResult: true, showMatching: true, filterFavorites: false };
    secGroups: MultiselectModelI = { showResult: true, showMatching: true, filterFavorites: false };
    summary: string;

    @Output() modelChange = new EventEmitter();

    quotaTypes = EventQuotaService.quotaTypes;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {}

    onModelChange() {
        this.modelChange.emit(this.model);
    }

    ngOnInit() {
        this.model.durationType = this.model.durationType || "D";
        this.model.active = S25Util.isDefined(this.model.active) ? this.model.active : true; //default to active
        this.model.groupItems = S25Util.isDefined(this.model.groupItems) ? this.model.groupItems : false; //default to per location/obj quotas
        this.model.skipExisting = S25Util.isDefined(this.model.skipExisting) ? this.model.skipExisting : false;
        this.model.useQuantity = true;

        if (!this.model.type || [4, 99, 3].indexOf(S25Util.toInt(this.model.type.itemId)) > -1) {
            //4 and 99 are legacy types that only worked on users.
            this.model.type = { readOnly: false, itemId: "3" };
        } else {
            this.model.type.itemId = this.model.type.itemId && this.model.type.itemId.toString();
        }

        if (this.model.addedLocations) this.locations.addedItems = this.model.addedLocations;
        if (this.model.addedOrganizations) this.organizations.addedItems = this.model.addedOrganizations;
        if (this.model.addedGroups) this.secGroups.addedItems = this.model.addedGroups;

        this.init = true;
    }

    updateDuration = function (inputVal: any) {
        if (!Number.isInteger(inputVal.days) && inputVal.days != null) {
            this.candidateVal.days = 0;
        }
        if (inputVal.hours > 23 || (inputVal.hours !== null && !Number.isInteger(inputVal.hours))) {
            this.candidateVal.hours = 23;
        }
        if (inputVal.minutes > 59 || (inputVal.minutes !== null && !Number.isInteger(inputVal.minutes))) {
            this.candidateVal.minutes = 59;
        }
        if (inputVal.days < 0) this.candidateVal.days = 0;
        if (inputVal.hours < 0) this.candidateVal.hours = 0;
        if (inputVal.minutes < 0) this.candidateVal.minutes = 0;
        this.cd.detectChanges();
    };

    @Bind
    save() {
        this.model.addedOrgs = this.organizations.addedItems;
        this.model.removedOrgs = this.organizations.removedItems;

        this.model.addedLocations = this.locations.addedItems;
        this.model.removedLocations = this.locations.removedItems;

        this.model.addedGroups = this.secGroups.addedItems;
        this.model.removedGroups = this.secGroups.removedItems;

        if (this.validate()) {
            return EventQuotaService.set([this.model]).then(
                (resp) => {
                    this.close("save");
                    return true;
                },
                (err) => {
                    S25Util.showError(err);
                    return false;
                },
            );
        }
        return false;
    }

    @Bind
    close(type: string) {
        if (type === "save") {
            this.onSave?.();
        } else if (type === "cancel") {
            this.cancel?.();
        }
    }

    validate() {
        if (!this.model.itemName || this.model.itemName === "") {
            S25Util.showError("Please enter a Quota Name");
            return false;
        } else if (this.model.type.itemId == 2 && !this.organizations.selectedItems.length) {
            S25Util.showError("Organization based quotas require at least organization to be selected");
            return false;
        } else if (!this.model.duration) {
            S25Util.showError("Please enter a value for Duration");
            return false;
        } else if (!this.model.quantity && (this.model.maxTime === "P0DT00H00M" || !this.model.maxTime)) {
            S25Util.showError("Please enter a value for Occurrence Count or Total Time");
            return false;
        }
        return true;
    }

    setQuotaType() {
        this.organizations = { showResult: true, showMatching: true, filterFavorites: false };
        this.onModelChange();
        this.cd.detectChanges();
    }
}
