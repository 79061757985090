//@author: travis
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { S25EditEventQuotasComponent } from "./s25.edit.event.quotas";
import { S25EditableModule } from "../../s25-editable/s25.editable.module";
import { S25MultiselectModule } from "../../s25-multiselect/s25-multiselect.module";
import { S25ToggleButtonModule } from "../../s25-toggle-button/s25.toggle.button.module";
import { S25DatePatternModule } from "../../s25-datepattern/s25.datepattern.module";
import { S25EventQuotasListComponent } from "./s25.event.quotas.list.component";
import { S25TableModule } from "../../s25-table/s25.table.module";
import { S25ModalModule } from "../../s25-modal/s25.modal.module";
import { S25RadioModule } from "../../s25-radio/s25.radio.module";
import { S25LoadingInlineModule } from "../../s25-loading/s25.loading.inline.module";

@NgModule({
    declarations: [S25EditEventQuotasComponent, S25EventQuotasListComponent],
    imports: [
        CommonModule,
        FormsModule,
        S25EditableModule,
        S25MultiselectModule,
        S25ToggleButtonModule,
        S25DatePatternModule,
        S25TableModule,
        S25ModalModule,
        S25RadioModule,
        S25LoadingInlineModule,
    ],
    providers: [S25EditEventQuotasComponent, S25EventQuotasListComponent],
    exports: [S25EditEventQuotasComponent, S25EventQuotasListComponent],
})
export class EventFormSettingsModule {
    constructor() {}
}
