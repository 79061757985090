//@author: mandy

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { EventStateDefaultComponent } from "./event.state.default.component";
import { S25ToggleButtonModule } from "../../../s25-toggle-button/s25.toggle.button.module";

@NgModule({
    declarations: [EventStateDefaultComponent],
    imports: [CommonModule, FormsModule, S25ToggleButtonModule],
    providers: [EventStateDefaultComponent],
    exports: [EventStateDefaultComponent],
})
export class EventStateDefaultModule {
    constructor() {}
}
